import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";
import { CountUp } from "./plugins/countUp.js";

AOS.init({
  useClassNames: false,
  disableMutationObserver: false,
  duration: 800,
  offset: 0,
  once: true
});
AOS.refresh(true);


function vh() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

vh();

window.addEventListener('resize', () => vh());


const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
  return document.documentElement || document.body;
};

if (header.classList.contains("fixed")) {
  document.addEventListener("scroll", () => {
    if (scrollContainer().scrollTop > 0) {
      header.classList.add("scrolled");
    } else if (scrollContainer().scrollTop == 0) {
      header.classList.remove("scrolled");
    }
  });
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $(".menu-item.dropdown.active .dropdown-menu").slideUp({
      complete: function () {
        $(this).closest(".dropdown").removeClass("active");
      },
    });

    //open current submenu
    $(this).closest(".menu-item.dropdown").toggleClass("active");
    if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
      e.preventDefault();
      $(this).next(".dropdown-menu").slideDown();
    }
  }
});

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".navbar-nav").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
    : scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

const awardsArrow = document.querySelector('.awards-sec .arrow-top');
if (awardsArrow) {
  const footerArrow = document.querySelector(".footer .arrow");
  if (footerArrow) {
    footerArrow.style.display = 'none'
  }
}


const expertise = document.querySelector('.expertise')

if (expertise) {
  new Swiper('.expertise__swiper', {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: false,
    scrollbar: {
      el: ".expertise .swiper-scrollbar",
      draggable: true
    },
    autoplay: {
      delay: 4000,
    },
    breakpoints: {
      1200: {
        slidesPerView: 3,
        loop: true,
      },
      768: {
        loop: true,
        slidesPerView: 2,
      },
    }
  })
}

const brands = document.querySelector('.brands')

if (brands) {
  
  const lengthSlides = brands.querySelectorAll('.swiper-slide').length

  new Swiper('.brands__slider', {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: false,
    // loopedSlides: 24,
    scrollbar: {
      el: ".brands .swiper-scrollbar",
      draggable: true
    },
    autoplay: {
      delay: 4000,
    },
    breakpoints: {
      1200: {
        slidesPerView: 3.3,
        loop: lengthSlides <= 3 ? false : true,
      },
      768: {
        slidesPerView: 3,
        loop: lengthSlides <= 3 ? false : true,
      },
      520: {
        slidesPerView: 2,
        loop: lengthSlides <= 3 ? false : true,
      },
    }
  })
}

const jobsSec = document.querySelector('.jobs-sec')

if (jobsSec) {
  new Swiper('.jobs-sec__slider', {
    slidesPerView: 1,
    spaceBetween: 26,
    loop: false,
    // loopedSlides: 24,
    scrollbar: {
      el: ".jobs-sec .swiper-scrollbar",
      draggable: true
    },
    autoplay: {
      delay: 4000,
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
        loop: true,
      },
      768: {
        slidesPerView: 3,
        loop: true,
      },
      500: {
        slidesPerView: 2,
        loop: true,
      }
    }
  })
}

const newsSec = document.querySelector('.news-sec')

if (newsSec) {
  new Swiper('.news-sec__slider', {
    slidesPerView: 1,
    spaceBetween: 40,
    autoplay: {
      delay: 4000,
    },
    breakpoints: {
      1500: {
        slidesPerView: 3,
      },
      1100: {
        slidesPerView: 2.18,
      },
      768: {
        slidesPerView: 1.9,
      }
    }
  })
}

$('select').select2({
  minimumResultsForSearch: -1
});

const footer = document.querySelector('.footer')

if (footer) {
  function swiperButtonsFunc(swiper) {
    if (swiper.navigation.nextEl.classList.contains('swiper-button-disabled')) {
      swiper.navigation.nextEl.classList.add('hidden');
    } else {
      swiper.navigation.nextEl.classList.remove('hidden');
    }

    if (swiper.navigation.prevEl.classList.contains('swiper-button-disabled')) {
      swiper.navigation.prevEl.classList.add('hidden');
    } else {
      swiper.navigation.prevEl.classList.remove('hidden');
    }
  }
  const footerSlider = new Swiper('.footer__slider', {
    slidesPerView: 1,
    spaceBetween: 40,
    allowTouchMove: false,
    autoHeight: true,
    navigation: {
      nextEl: '.footer__slider__btn .next',
      prevEl: '.footer__slider__btn .prev',
      draggable: true
    },
    on: {
      slideNextTransitionStart: function (swiper) {
        const index = swiper.realIndex;
        const slide = swiper.slides[index - 1];
        const input =
            slide.querySelector("input") || slide.querySelector("textarea");

        if (input && !input.value) {
          const field = input.getAttribute("data-name");
          noticeError(field + ' is required field!');
          footerSlider.slidePrev(0);
        }

        if (isNaN(input.value)) {
          const field = input.getAttribute("data-name");

          if (field === 'Phone') {
            noticeError('Only numbers are available for the ' + field + ' field');
            footerSlider.slidePrev(0);
          }
        }
      },
      slideChange: function (swiper) {
        swiperButtonsFunc(swiper);
      },
      init: function (swiper) {
        swiperButtonsFunc(swiper);
      }
    },
  })
  
  document.querySelector('#footer_form .swiper-wrapper').addEventListener('wheel', (e) => {
    e.preventDefault()
  }, {passive: true})
  document.querySelector('#footer_form .swiper-wrapper').addEventListener('touchmove', (e) => {
    e.preventDefault()
  }, {passive: true})

  function ifFirst () {
    if (footerSlider.activeIndex === 0) {
      document.querySelector('.footer__slider__btn .prev').style.display = 'none'
    } else {
      document.querySelector('.footer__slider__btn .prev').style.display = 'block'
    }
  }

  ifFirst()

  footerSlider.on('slideChange', function(){
    ifFirst() 

    if (footerSlider.activeIndex === 4) {
      document.querySelector('.footer__slider__btn.send-btn').style.display = 'block'
      document.querySelector('.footer__slider__btn .next').style.display = 'none'
    } else {
      document.querySelector('.footer__slider__btn.send-btn').style.display = 'none'
      document.querySelector('.footer__slider__btn .next').style.display = 'block'
    }

    let looking = document.querySelector('.select2-selection__rendered').getAttribute('title').trim()

    if ((looking === 'Join the team' && footerSlider.activeIndex === 3) || (looking === 'A new role' && footerSlider.activeIndex === 3)) {
      document.querySelector('.footer__slider__btn.send-btn').style.display = 'none'
      document.querySelector('.footer__slider__btn .next').style.display = 'block'
    } else if ((looking !== 'Join the team' && footerSlider.activeIndex === 3) || (looking !== 'A new role' && footerSlider.activeIndex === 3)) {
      document.querySelector('.footer__slider__btn.send-btn').style.display = 'block'
      document.querySelector('.footer__slider__btn .next').style.display = 'none'
    }
 });

}

const stats = document.querySelector('.stats')

if (stats) {
  new Swiper('.stats__inner', {
    slidesPerView: 2,
    allowTouchMove: true,
    scrollbar: {
      el: ".stats__inner .swiper-scrollbar",
      draggable: true
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    breakpoints: {
      1024: {
        slidesPerView: 5,
        allowTouchMove: false,
      },
      768: {
        slidesPerView: 4,
        allowTouchMove: true,
      },
      540: {
        slidesPerView: 3,
        allowTouchMove: true,
      }
    }
  })

  const counts = Array.from(document.getElementsByClassName("count-up"));

  let idNumber = 1;
  counts.forEach((count) => {
    const id = `count-up-${idNumber}`,
    value = count.textContent

    new CountUp({
      id,
      value,
    });
    idNumber++;
  })
}

const services = document.querySelector('.services')

if (services) {
  new Swiper('.services__grid', {
    slidesPerView: 1,
    allowTouchMove: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    breakpoints: {
      769: {
        slidesPerView: 3,
        allowTouchMove: true,
      },
      540: {
        slidesPerView: 2,
        allowTouchMove: true,
      }
    }
  })
  window.addEventListener('resize', () => {
    document.querySelectorAll('.services__item').forEach(item => {
      item.style.height = item.offsetWidth + 'px' 
    })
  })
  document.querySelectorAll('.services__item').forEach(item => {
    item.style.height = item.offsetWidth + 'px' 
  })
}

const selectFile = document.querySelector('.select-file')

if (selectFile) {
  const input = selectFile.querySelector('input')

  function fileUpload(e) {
    const allSizes = []
    
    Array.from(e.target.files).forEach(item => {
      allSizes.push(item.size)
    })
  
    const bytes  = allSizes.reduce((total, amount) => total + amount)
    const fileSize = (bytes / (1024 ** 2)).toFixed(2)
  
    if (e.target.files.length > 5) {
      alert("Max. files: 5");
      e.target.value = null
    }
  
    if (fileSize > 60) {
      alert("Max. file size: 60 MB");
      e.target.value = null
    }
  
    const results = selectFile.querySelector('.res')
    results.innerHTML = ''
  
    Array.from(e.target.files).forEach(item => {
      results.innerHTML += `<li>${item.name}</li>`
    })

    selectFile.querySelector('p').style.display = 'none'
  }

  input.addEventListener('input', (e) => {
    fileUpload(e)
  })

  selectFile.addEventListener('dragover', (e) => {
    e.preventDefault()
    selectFile.classList.add('dragover')
  })

  selectFile.addEventListener('dragleave', (e) => {
    e.preventDefault()
    selectFile.classList.remove('dragover')
  })

  selectFile.addEventListener('drop', (e) => {
    selectFile.classList.remove('dragover')
    input.files = e.dataTransfer.files;

    const dT = new DataTransfer();
    Array.from(e.dataTransfer.files).forEach(item => {
      dT.items.add(item)
    })
    input.files = dT.files;

    e.preventDefault();

    
    const allSizes = []
    
    Array.from(input.files).forEach(item => {
      allSizes.push(item.size)
    })
    
    const bytes  = allSizes.reduce((total, amount) => total + amount)
    const fileSize = (bytes / (1024 ** 2)).toFixed(2)
    
    if (input.files.length > 5) {
      alert("Max. files: 5");
      input.value = ""
    }
    
    if (fileSize > 60) {
      alert("Max. file size: 60 MB");
      input.value = ""
    }
  
    const results = selectFile.querySelector('.res')
    results.innerHTML = ''
  
    Array.from(input.files).forEach(item => {
      results.innerHTML += `<li>${item.name}</li>`
    })

    selectFile.querySelector('p').style.display = 'none'
  })
}

const contentBlockImagesArr = [...document.querySelectorAll('.content img')];
contentBlockImagesArr.forEach(img => {
	// Create a new div element to be the wrapper
	const wrapper = document.createElement('div');
	wrapper.className = 'news-sec__card__img corners';

	const corners = ['top-left', 'top-right', 'bottom-right', 'bottom-left'];
	for (const corner of corners) {
		const cornerElement = document.createElement('div');
		cornerElement.className = corner;
		wrapper.appendChild(cornerElement);
	}

	// Create the div element for the image and append it to the wrapper
	const imageInner = document.createElement('div');
	imageInner.className = 'news-sec__card__img__inner';
	wrapper.appendChild(imageInner);

	// Replace the img with the wrapper
	img.parentNode.replaceChild(wrapper, img);

	// Append the img to the new image inner div
	imageInner.appendChild(img);
})